@import "../../assets/css/Variables.scss";

.dropdown{
  width: 220px !important;
  background: $default-button-color;
  border-radius:5px !important;
  color: $primaryFontColor;
  min-height: 32px !important;

  input::placeholder{
      color: $primaryFontColor !important;
  }
  &:focus, &:hover{
      border-color: $default-button-color !important;
      box-shadow: none !important;
  }
}
