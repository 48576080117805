.welcomeRow
{
  justify-content: center;
}
@media (max-width:1000px) {
  .welcomeRow
  {
    padding-top: 25px;
  }
}
@media (max-width:766px) {
  .welcomeRow
  {
    padding-top: 0px;
  }
}

