.search-stat-text
{
    height: 36px;
    width: 306px;
    left: 162px;
    top: 589px;
    Blend: Pass through;
}

.searchStatFont{
    font-size: 15px;
    color:black !important;
}
