@import "../../assets/css/Variables.scss";

.add{
    cursor: pointer;
    font-size:16px;
    &:hover{
        color:black;
    }
    svg{
        margin-right:10px;
    }
}

.remove{
    cursor: pointer;
    font-size:16px;
    svg{
        margin-right:10px;
    }
}