header{
    padding-top:22px;
    background: #FFFFFF !important;
}

.logo{
    position: absolute;
    width: 319.72px;
    height: 80px;
    left: 0px;
    top: -25px;
}

.logoutButton{
    color:black;
}

.headerRight{
    float: right;
    padding-right: 30px;
    margin-top: 14px;
}
