@import "../../assets/css/Variables.scss";

.myOrderStyles{
    margin-bottom: 20px;
}

.goBack{
    font-weight: 600;
    font-size: 16px;
    &:hover {
        color: $default-button-color;
      }
}

.myOrderDetail{
    margin-top: 25px;
}
.myOrderBack{
    text-decoration: underline;
}
.buttonTop{
    margin-top: 35px;
    padding-left: 15px;
    div {
        padding-top: 0px;
        padding-left: 0px;
      }
}

.selectAll {
    text-transform: uppercase;
    font-weight: normal;
    margin-right: 1rem;
    padding: .5rem 12px;
}
