@import "../../assets/css/Variables.scss";

// .marginBottomTitle{
//     margin-bottom:20px;
// }

.frame{
    position: relative;
    background:#ccc;
    width:590px;
    margin-top:100px;
    margin-left:auto;
    margin-right:auto;
    margin-bottom:20px;
    background:rgba(255, 255, 255, 0.8);
    padding-top:30px;
    padding-left:20px;
    padding-right:20px;
    z-index:93;        

    input{
        border:0px;
        border-bottom:1px dashed #000000;
        border-radius: 0;
        background-color: rgba(0, 0, 0, 0) !important;
        height:70px;
        font-size:16px;
        padding-top:50px;
    }

    label{
        font-weight: bold;
        padding-top:50px;
        padding-bottom:0px;
        margin-bottom:0px;
    }

    .loginButton{
        margin-top:60px;
        background-color: #000000;
        height:60px;
    }

    .forgotPasswordText{
        margin-top:15px;
    }

    .tocLink{
        margin-top:50px;
        cursor: pointer;
    }

    .registerLink{
        margin-top:15px;
        margin-bottom:30px;
    }

    
}

.noPadding{
    padding: 0;
}


.title{
    font-weight:600;
    font-size: 5vh;
    font-family: SuissebyJLItalic;
    line-height:  5vh;
    margin-bottom:20px;
    text-transform: uppercase;
}

.backButton {
    font-weight: bold;
}

.backArrow {
    margin-right: .5rem;
}

.logo{
    position: absolute;
    right:0;
    top:0;
}
