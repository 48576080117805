@import "../../assets/css/Variables.scss";

.mySelectionListControllesStyels{
    margin-bottom: 20px;
}

.goBack{
    font-weight: 600;
    font-size: 16px;
    &:hover {
        color: $default-button-color;
      }
}

.buttonStyle
{
    width: 177px;
    height :30px;
    font-style: normal;
    font-weight:400;
    line-height: 17px;
    letter-spacing: -0.02em;
    text-align: center;
    font-size: 14px;
    text-transform: uppercase;
    color: $primaryFontColor;
    background: $default-button-color;
    border: $light-ash-color;
    margin-right: 15px;
    &:hover {
        background-color: $default-button-color;
      }
}
.buttonTop{
    margin-top: 35px;
    padding-left: 15px;
}

.selectionImage{
    padding-left: 15px;
    margin-top: 35px;
    width: 100%;
    height: auto;
    left: 135px;
    top: 243px;
}
