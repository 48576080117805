@import "../../assets/css/Variables.scss";

.style {
  font-style: normal;
  font-weight: bold;
  text-align: center;
  font-size: 16px;
  color: $primaryFontColor;
  background: $default-button-color;
  border: $light-ash-color;
  line-height: 1;


  &:hover {
    background-color: $default-button-color !important;
  }
  &:focus {
    background-color: $default-button-color !important;
  }
  &:active {
    background-color: $default-button-color !important;
  }

  @media screen and (max-width: 1400px) {
    font-size: 14px;
  }
}

.small {
  padding: 7px 25px 7px 25px;
  font-size: 14px;
//   height: 28px;
}
.medium {
    padding: 8px 32px 8px 32px;
    font-size: 16px;
    height: 32px;
}
