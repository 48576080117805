@import "../../assets/css/Variables.scss";

.cattitle {
  font-size: 14px;
  font-weight: bold;
  // text-transform: uppercase;
}

.bodyText {
  font-size: 14px;
}

.check{
  label{
    font-size: 13px;
  }
  input[type="checkbox"] {
    width: 12px;
    border-radius: 2px;
    height: 15px;
    border: 1px solid #53565A;
  }
}


.buttonStyle
{
    height :30px;
    font-style: normal;
    font-weight: 500;
    line-height: 17px;
    letter-spacing: -0.02em;
    text-align: center;
    font-size: 14px;
    text-transform: uppercase;
    color: white !important;
    padding:6px;
    background: $default-button-color;
    border: $light-ash-color;
    border-radius: 0.25rem;
    margin-right: 15px;
    &:hover {
        background-color: $default-button-color !important;
        color:white !important;
      }
    &:focus {
        background-color: $default-button-color !important;
      }
    &:active{
      background-color: $default-button-color !important;
    }
    
}
.bodyAlign{
  padding-left: 15px;
}

.disabled{
  background-color: #808080 !important;
  &:hover, &:focus, &:active{
    background-color: #808080 !important;
  }
}