@import "../../assets/css/Variables.scss";

.iconAddToSelection{
    padding: 7px 6px;
    background-position: 6px 7px;
    background-repeat: no-repeat;
    background-color: $light-ash-color;
}

.iconAddToSelection:hover {
    background-position: 6px 7px;
    background-repeat: no-repeat;
    background-color: $pimary-dark-color;
}

.iconBig{
    padding: 7px 6px;
    background-position: 8px 9px;
    background-repeat: no-repeat;
}

.iconBig:hover {
    color: white;
    background-position: 8px 9px;
    background-repeat: no-repeat;
}

.add{
    background: url('../../assets/images/folder-plus.svg') center;

    &:hover{
        background: url('../../assets/images/folder-plus-hover.svg') center;
    }
}

.remove{
    background: url('../../assets/images/folder-minus.svg') center;

    &:hover{
        background: url('../../assets/images/folder-minus-hover.svg') center;
    }
}

