.backgroundVideo{
   position:fixed;
   right: 0;
   bottom: 0;
   min-width: 100%;
   min-height: 100%;
   background-color: #e4e3e4;
   -webkit-filter: blur(20px);
   -moz-filter: blur(20px);
   -o-filter: blur(20px);
   -ms-filter: blur(20px);
   filter: blur(20px);
}

.backgroundImage{
   position:fixed;
   top:0;
   left:0;
   width:100vw;
   height:100vh;
}

.footer{
   position:fixed;
   bottom:0;
   left:0;
}

.logo{
   position: fixed;
   right:0;
   top:0;

}

.toast{
   padding:0 100px 13px 100px;
   top:40% !important;
   background:white !important;
}
.tocIframe{
   width: 100%;
    position: absolute;
    height: 800px;
}