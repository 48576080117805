@import "../../assets/css/Variables.scss";

.myselectionButton{
    color: $default-button-color;
    margin-right:30px;    
    font-size: 13px;
    span{
        color:blue;
        font-weight: bold;        
    }
    &:hover {
        color: $default-button-color !important;
      }
    &:focus {
        color: $default-button-color !important;
      }
    &:active{
      color: $default-button-color !important;
    }
    
}