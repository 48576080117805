@import "../../assets/css/Variables.scss";


.marginBottomTitle{
    margin-bottom:20px;
}

.frame{
    margin-top:30px;
    position: relative;
    background:#ccc;
    width:590px;
    margin-left:auto;
    margin-right:auto;
    background:rgba(255, 255, 255, 0.8);
    padding-top:30px;
    padding-left:20px;
    padding-right:20px;
    z-index:93;
    input{
        border:0px;
        border-bottom:1px dashed #000000;
        border-radius: 0;
        background-color: rgba(0, 0, 0, 0) !important;
        height:70px;
        font-size:16px;
        padding-top:50px;
        color:black; 
        margin-left:0px;
    }

    label{
        font-weight: bold;
        padding-top:50px;
        padding-bottom:0px;
        margin-bottom:0px;
    }

    .button{
        margin-top:30px;
        background-color: #000000;
        height:60px;
    }

    .termsCheckbox{
        margin-top:15px;
        input{
            height:auto;
            box-shadow: 0px 0px 0px 1px rgb(0, 0, 0);            
        }
        label{
            padding-top:0px;
            font-weight: normal;
        }
        
    }

    .backButton{
        margin-bottom: 20px;
        cursor: pointer;
        span{
            padding-left:10px;
            font-weight: bold;
        }
        svg{
            margin-left:-3px;
        }
    }

    textarea{
        margin-top:50px;
    }
}

.noPadding{
    padding: 0;
}


.title{
    font-weight:600;
    font-size: 5vh;
    font-family: SuissebyJLItalic;
    line-height:  5vh;
}

.dropdown{    
    button{
        background:transparent !important;
        color:black;
        border:none;
        height:70px;
        font-size:16px !important;
        text-align: left;  
        border-bottom:1px dashed #000000;
        width:100%;
        padding-top:40px;
        &:focus, &:hover{
            color:black !important;
        }     
    }
}

.accountTypeContainer{
    border-bottom:1px dashed black;
    padding:0;
    margin:0px;
    .react-dropdown-select-content{
        font-weight: bold !important;
    }
    
    div{
        border:0 !important;
    }
    
    input{
        border-bottom: 0;
        &::placeholder{
            color:black;
            font-size:14px;
            padding:0 !important;
            margin:0 !important;
        }
    }

    // &.react-dropdown-select-item{
    //     // margin-top:50px;
    //     font-weight: bold;
    //     background:none !important; 
    //     border-bottom: 1px solid #000 !important; 
    //     padding-top:10px;
    //     padding-bottom:10px;
    //     color:#000;
    //     &:hover{
    //         background:#f6f6f6;
    //         font-weight: 600;
    //     }
    // }
}

.select{
    box-shadow: none !important;
    line-height: 20px;
}

.backButton{
    font-size:16px;
    font-weight: 600 !important;
}