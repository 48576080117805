header{
    padding-top:22px;
}

.logo{
    position: absolute;
    width: 319.72px;
    height: 80px;
    left: 0px;
    top: -25px;
    margin-left: -15px;
}

.logoutButton{
    color:black;
}