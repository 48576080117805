@import "../../assets/css/Variables.scss";


.thumbnailContainer {
    cursor: pointer; 
    position: relative;

    .smallGrid{
        height: 130px !important;
        margin-top:30px;
        @media screen and (min-width: 992px) {
            height: 130px;
        }
    }   

    .thumb {
        
        height: 200px;
        @media screen and (min-width: 992px) {
            height: 400px;
        }
        border:1px solid rgb(243, 243, 243);

        .thumbImage{
            max-width: 100%;
            max-height: 100%;
            object-fit: cover;
            margin: auto;
        }
        
        .smallGridHeight{

        }

        .imgContainer {
            overflow: hidden;

            

            .playIcon{
                top:0;
                bottom:0;
                left:0;
                right:0;
    
                margin-top: auto;
                margin-bottom: auto;
                margin-left: auto;
                margin-right:auto;
    
                position: absolute;
                height: 30%;
                width: 30%;
                color:white;
                z-index:500;
            }
        }
        
        .adjustTopPosition{
            bottom:-50px !important;
        }

        .buttonContainer {

            position: absolute;
            bottom: -8px;
            right: -4px;

            .iconAddCollection{
                border-radius: 5px;
                right: 40px;
                border:2px solid #000;
            }

            .iconExpand {
                right: 43px;
                bottom: 10px;
            }

            .iconDownload {
                right: 38px;
                border-radius:5px;
                > a, > span{
                    width:36px; 
                    height:34px; 
                    border:2px solid #000;
                    border-radius: 5px;
                }
            }
        }
        .buttonAlign{
            bottom: -8px;
            right: 32px;
        }
    }

    .thumbTitle{
        font-size: 16px;
        line-height: 16px;
        // text-overflow: ellipsis;
        // white-space: nowrap;
        word-wrap: break-word;
        overflow: hidden;
        width:calc(100% - 90px) !important;
    }

    .styleName{
        font-size: 13px;
        line-height: 13px;
    }

    .thumbTitleFullWidth{
        font-size: 16px;
        line-height: 16px;
        text-overflow: ellipsis;
        word-wrap: break-word;
        overflow: hidden;
        -webkit-line-clamp: 2;
        height:50px;
    }

    .imageTitleContainer{
        // border: 1px solid $dark-ash-color;
        box-sizing: border-box;
    }

    &:hover .buttonContainer {
        display: block;
    }

    .highlitedBorder{
        // border: 2px solid $pimary-dark-color !important;
    }

    .viewDetailButton {
        position: absolute;
        bottom:80px; 
        text-align: center;
        left:calc(50% - 65px);

        button{
            border-radius: 5px;
            background:white !important;
            padding:4px 24px 4px 24px;
        }
    }

    .selectedMark {
        position: absolute;
        left: 0;
        height: 34px;
        width: 34px;
        background-color: #fff;
        border:2px solid #000;
        border-radius: 5px;
    }
    .selectedMark:after {
        content: "";
        position: absolute;
        display: none;
        border-width: 0 3px 3px 0;
    }

    .inputCustomCheckbox {
        display: block;
        position: relative;
        padding-left: 35px;
        cursor: pointer;
        margin-bottom: 5px;
    
        label{
            cursor: pointer;
            font-size: 14px;
        }
    
        input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;
    
            &:checked ~ .selectedMark {
                background-color: #000;   
                border:1px solid #000;
            }
    
            &:checked ~ .selectedMark:after {
                display: block;
            }
    
            &:checked ~ .selectedMark {
                background-color: #000;
            }
        }
    
        &:hover input ~ .selectedMark {
            background-color: #fff;
            border:2px solid #000;
        }
    
        &:hover input:checked ~ .selectedMark {
            background-color: #000;
            border:1px solid #000;
        }
    
        .selectedMark:after {
            left: 10px;
            top: 2px;
            width: 11px;
            height: 22px;
            border: solid white;
            border-width: 0 2px 2px 0;
            transform: rotate(45deg);
        }
    }  
}
