.categoryBottom
{
   padding-top: 50px;
}

.categoryTitle{
   position:absolute;
   top:-10px;
   font-size:106px;
   font-style: italic;
   line-height: 106px;
   left:8px;
   font-weight: 600;
   width:50px;
}

.categorySpread{
   display: flex;
   -webkit-justify-content: space-between;
}

.categoryRow{
   margin-right: -15px !important;
}
