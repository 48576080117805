.welcomeStyle
{
  display: contents;
  position: absolute;
  left: 0px;
  top: 81px;
}
.mediaStyle
{
  width: 100%;
  height: auto;
}