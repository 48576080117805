@import "../../assets/css/Variables.scss";

.myOrderButton{
    color: $default-button-color;
    margin-right:30px;    
    font-size: 13px;
    &:hover {
        color: $default-button-color !important;
      }
    &:focus {
        color: $default-button-color !important;
      }
    &:active{
      color: $default-button-color !important;
    }
}