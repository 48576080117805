body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h3{
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  color: #1a1a1a;
  overflow-wrap: break-word;
}

.modal-content{
  border-radius: 0 !important;
}

.modal-title{
  font-size: 16px !important;
  font-weight: bold !important;
}

.search-enter {
  width: 0px;
}
.search-enter-active {
  width: 295px;
  transition: width 1200ms;
}
.search-exit {
  width: 295px
}
.search-exit-active {
  width: 0px;
  transition: width 1200ms;
}
.search-enter-done, .search-exit-done{
  width: 295px;
}