@font-face {
    font-family: SuissebyJL;
    src: url(../fonts/SuissebyJL-Regular-WebXL.woff);
}

@font-face {
    font-family: SuissebyJLBoldItalic;
    src: url(../fonts/SuissebyJL-BoldItalic-WebXL.woff);
}

@font-face {
    font-family: SuissebyJLItalic;
    src: url(../fonts/SuissebyJL-Italic-WebXL.woff);
}

@font-face {
    font-family: SuissebyJLBook;
    src: url(../fonts/SuissebyJL-Book-WebXL.woff);
}