@import "../../assets/css/Variables.scss";

.buttonStyle{
    color: #C9C9C9;
    background-color: #fff;
    border-color: #fff !important;
    border-radius: 0 !important;
    &:hover{
        background-color: black;
    }
}

.highlighted{
    color: $pimary-dark-color;
}
