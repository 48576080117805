@import "../../assets/css/Variables.scss";

.buttonStyle
{
    width: 177px;
    height :30px;
    font-style: normal;
    font-weight:400;
    line-height: 17px;
    letter-spacing: -0.02em;
    text-align: center;
    font-size: 14px;
    text-transform: uppercase;
    color: $primaryFontColor;
    background: $default-button-color;
    border: $light-ash-color;
    margin-right: 15px;
    &:hover {
      background-color: $default-button-color !important;
    }
  &:focus {
      background-color: $default-button-color !important;
    }
  &:active{
    background-color: $default-button-color !important;
  }
}