.modalFooter
{
   margin-left: -17px;
   margin-inline-end:auto;
   border-top: 0 none !important;
   background-color: transparent;
   padding-top: 800px !important;
}

.modalButton
{
   text-align: left !important;
   height: 40px;
   width: 213px;
   left: 0px;
   top: 0px;
   border-radius: 5px;
   background-color: #000000 !important;
   border-color: #000000 !important;
}

.modalBody
{
   background-color: #fff;
}
.modalFooterButton{
   margin-left: 15px !important;
}
.modal > div > div {
   background-color: transparent !important;
   border: none;
}

.tocIframe{
   width: 100%;
    position: absolute;
    height: 800px;
}