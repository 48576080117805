@import "../../assets/css/Variables.scss";

.iconDownloadButton{
    background: url('../../assets/images/icon-download.svg') center;

}

.iconDownloadButton:hover {
    background: url('../../assets/images/icon-download_hover.svg') center;
    
}

.menuHanlder{
    .downloadTemplateContainer{
        display: none;
    }

    &:hover .downloadTemplateContainer{
        display: block;
    }
}
