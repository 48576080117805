@import "../../assets/css/Variables.scss";

.toolBarIntro{
    min-height: 80px;
    @media (min-width:768px) {
        min-height: 48px;
    }
    display:flex;
    flex-direction: column;
}

.toolBarWrapper {
    line-height: 2;
    font-size: 14px;
}

.alignRight{
    float: right;
}

.verticalSeparator {
    border-left: 1px solid $ash-color;
    width: 1px;
    height: 80%;
}