
@import "../../assets/css/Variables.scss";

.previewColumns{
    padding:20px;

    h4{
        margin-top:30px;
        margin-bottom:20px;
        font-size:22px;
        font-weight: 700;
        text-transform: uppercase;
    }

    .downloadTemplates{
        margin-top:50px;
    }
    
}

.zoomCursor{
    cursor: zoom-in;
}

.topLinks{
    a{
     margin-right:20px;
     cursor: pointer;
     &:hover{
         color:black;
     }
    }
}

.prev{
    position:absolute;
    top:50%;
    left:-56px;
    cursor: pointer;
    &:hover{
        -webkit-transform: scaleX(-1);
        transform: scaleX(-1);
        content:url(../../assets/images/next.svg);
    }
}

.next{
    position:absolute;
    top:50%;
    right:-56px;
    cursor: pointer;
    svg{
        -webkit-transform: scaleX(-1);
        transform: scaleX(-1);
    }
    &:hover{
        content:url(../../assets/images/next.svg)
    }
}

.close{
    float:right;
    margin-top:-10px;
    margin-right:-5px;
    cursor: pointer;
}

.propertyTitle{
    font-size:14px;
    font-weight: 600;
}

.propertyValue{
    font-size:14px;
}

.hiddenInput{
    height:0;
    width:0;
    border:0;
    &:focus, &:focus-visible{
        border:0;
        outline:0;
        cursor:none !important;
    }
}