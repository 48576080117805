@import "../../assets/css/Variables.scss";

.myOrderStyles{
    margin-bottom: 20px;
}

.goBack{
    font-weight: 600;
    font-size: 16px;
    &:hover {
        color: $default-button-color;
      }
}

.orderImage{
    padding-left: 15px;
    margin-top: 35px;
    width: 100%;
    height: auto;
    left: 136px;
    top: 161px;
}
.myOrderTable{
    margin-top: 50px;
}
.tableHeading{
    background: rgba(0, 0, 0, 0.1);
}

.tableRow:nth-child(even) {
    background-color: #F5F5F5;
 }

.hiddenRef {
    height: 0;
    overflow: hidden;
}