.searchWrapper{
    float: right;
    margin-right: 30px;

    .keywordInput{
        font-size:14px;
        line-height: 22px;
        border: none;
        border-bottom:1px solid #000;
        box-sizing: border-box;
        float: left;
        margin-right:10px;
        padding-left:5px;
        padding-right: 5px;
        margin-top: -6px;
        @media (max-width:767px) {
            width:80vw;
        }

        &::placeholder{
            color:gray;
        }
        &:focus{
            border:2px solid #000 !important;
            border-radius: 5px;
            outline:0px;
        }
        &:focus-visible{
            border:2px solid #000 !important;
            border-radius: 5px;
            outline:0px;
        }
    }
    
    .button{
        padding: 0px;
        margin: -5px;
        border: 0px;
        background:transparent !important;
        float: right;
    }
}

