@import "~bootstrap/scss/bootstrap";
@import "../../assets/css/Variables.scss";
@import "../../assets/css/Fonts.scss";

$defaultBackgroundColor: #fff;
html{
    scroll-behavior: smooth;
}

body{
    font-family: $default-font;
    color:#000;
    padding:0px;
    margin:0px;
    overflow: scroll;
    caret-color: transparent; /*hiding the blinking cursor*/
}

input{
    caret-color: black !important;
}

header{
    background:$defaultBackgroundColor;
    height:140px;
    @media (min-width:768px) {
        height:82px;
    }
}

.btn-primary{
    &:focus{
        box-shadow: none !important;
        background-color: black !important;
    }
    &:active{
        background-color: black !important;
    }
    &:disabled {
        background-color: #666 !important;
        &:hover {
            background-color: #666 !important;
        }
    }
}

a{
    color:black;
    &:active, &:hover, &:visited{
        text-decoration: none;
        color:black;
    }
    &:hover{
        color: black;
    }
}

.loader {
    border: 1px solid #f3f3f3;
    border-radius: 50%;
    border-top: 2px solid black;
    width: 10px;
    height: 10px;
    -webkit-animation: spin 0.6s linear infinite; /* Safari */
    animation: spin 0.6s linear infinite;
    display:inline-block;
}

.expandButton{
    font-weight: bold;
    cursor: pointer;
 }

 #filterBlock{
     @media (max-width:768px) {
        display: none;
     }     
 }
 
 #filterBlock:target {
    @media (max-width:768px) {
        display: block;
     }
 }

/* Safari */
@-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

input{   
    &:-webkit-autofill,
    &:-webkit-autofill:hover, 
    &:-webkit-autofill:focus, 
    &:-webkit-autofill:active  {
        transition: background-color 5000s;
    }
}

.form-control:focus{
    border-color:black !important;
}

.form-control{
    &:focus{
        box-shadow:none;
        background:transparent;
    }
}

.toast{
    position:absolute;
    left:50vw;
    top:0px;
    z-index: 999;
    transform: translate(-50%,0);
    margin-top:5px;
}

.modal-dialog{
    max-width:50vw !important;
    .modal-body{    
        padding:0px;
        h1{
            margin-left:-10px;
            margin-top:-26px;
            font-size:120px;
        }
        p{
            margin:25px;
            margin-top:50px;
        }
    }
}

.heading{    
    /** below block defines the Asset Bank text as a resizable logo using regular text **/
    // font-size: 10vh; 
    // font-weight: bolder;
    // font-style: italic;
    // line-height: 11vh;
    // float:left;
    // margin-top:5px;

    position:absolute;
    top:-30px;
    left:0px;
    background: url('../images/bottom-logo.png') no-repeat;
    background-position: 20px 0px;
    height:240px;
    width:100%;
    z-index:2;

    span{
        display: block;
        margin-top:-12px;
    }
    .shadow{
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        box-shadow: none !important;
    }
}

.marginAdjustHeading{
    margin-left:-8px;
}

.logo{
    float:right;
    &.img{
        width:155.19px;
        height:50px;        
    }
}

// .footer{
//     height:50px;
//     width:100%;
//     background: url(../../assets/images/bottom-logo.png) no-repeat;
//     background-size: auto 50px;
//     position: sticky;
//     top:100vh;
// }

@media (min-width: 1500px) {
    .container {
        max-width: 1370px !important;
    }
}


//Custom Radio Button Styles
.inputCustomRadio {
    display: block;
    position: relative;
    padding-left: 25px;
    font-size:14px;
    cursor: pointer;
    margin-bottom: 5px;


    label{
        cursor: pointer;
    }

    input {
        opacity: 0; 

        &:checked ~ .checkmark {
            background-color: #fff;    
            border:1px solid #000;
        }

        &:checked ~ .checkmark:after {
            display: block;
        }
    }

    &:hover input ~ .checkmark {
        background-color: #fff;
        border:1px solid #000;
    }

    .checkmark:after {
        top: 3px;
        left: 3px;
        width: 13px;
        height: 13px;
        border-radius: 50%;
        background: black;
    }
}
  
  
.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 21px;
    width: 21px;
    background-color: #fff;
    border-radius: 50%;  
    border:1px solid #000;
}
   
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

//Custom Checkbox Styles
.inputCustomCheckbox {
    display: block;
    position: relative;
    padding-left: 35px;
    cursor: pointer;
    margin-bottom: 5px;

    label{
        cursor: pointer;
        font-size: 14px;
    }
    input[type="checkbox"]:disabled ~ .selectedMark {
        opacity: 0.2;
    }
    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;

        &:checked ~ .selectedMark {
            background-color: #000;   
            border:1px solid #000;
        }

        &:checked ~ .selectedMark:after {
            display: block;
        }

        &:checked ~ .selectedMark {
            background-color: #000;
        }
    }

    &:hover input ~ .selectedMark {
        background-color: #fff;
        border:2px solid #000;
    }

    &:hover input:checked ~ .selectedMark {
        background-color: #000;
        border:1px solid #000;
    }

    .selectedMark:after {
        left: 5px;
        top: 2px;
        width: 5px;
        height: 10px;
        border: solid white;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
    }
}  
  
.selectedMark {
    position: absolute;
    top: 3px;
    left: 0;
    height: 16px;
    width: 16px;
    background-color: #fff;
    border:2px solid #000;
}
   
.selectedMark:after {
    content: "";
    position: absolute;
    display: none;
    border-width: 0 3px 3px 0;
}
  
.hide{
    display:none;
}

.show{
    display:block;
    padding-top:25px;
    padding-left:10px;
}

.border-bottom{
    border-bottom: 1px solid #000;
    padding-bottom:15px;
    margin-bottom:15px;
}

.resetFilter{
    background:#000;
    color:#fff;
    padding:7px 20px;
    border-radius: 5px;
    font-size: 14px;
    &:hover, &:focus{
        color:#ccc !important;
    }
    svg{
        margin-left:10px;
    }
}

.pointer{
    cursor:pointer;
}

.sortOptions{
    a{
        background: black;
        padding:5px;
        color:white;
        border-radius: 5px;
        padding:5px 25px 5px 25px;
        width:50px;
        font-size: 14px;
    }
}

.full{
    box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.15), 0px 19px 38px rgba(0, 0, 0, 0.25);
    margin-top:50px;
    margin-bottom: 50px;
}

.react-dropdown-select-item{
    color:black;
    font-size:14px;
    background:white url(../images/downloadIcon.svg) no-repeat 98% !important;
    &:hover{
        background:#f5f5f5 url(../images/downloadIcon.svg) no-repeat 98% !important;
        color:black;
    }
} 
.react-dropdown-select-item-selected{
    color:#000 !important;
    background-color: white !important;
    background:white url(../images/downloadIcon.svg) no-repeat 98% !important;
    &:hover{
        background:#f5f5f5 url(../images/downloadIcon.svg) no-repeat 98% !important;
    }
}


.dowload-asset{
    .react-dropdown-select-input{
        margin: auto;
        font-size:14px;
        text-transform: uppercase;
        color: $primaryFontColor;
    }
    .react-dropdown-select-item-selected{
        background: none !important;
        text-transform: uppercase;
        &:hover{
            background:#f6f6f6 !important;
            text-transform: uppercase;
            color: $primaryFontColor !important;
        }
    }
    .react-dropdown-select-dropdown{        
        span{
            background: none !important;
            text-transform: uppercase;
            padding: 10px 0px 10px 20px;
            &:hover{
                background:$default-button-color !important;
                color: $primaryFontColor;
            }
        }
    }
    .react-dropdown-select-content{
        text-transform: uppercase;
        background:$default-button-color !important;
        color: $primaryFontColor;
        span{        
            margin:auto !important;
            font-size:14px;
            text-transform: uppercase;
            background:$default-button-color !important;
            color: $primaryFontColor !important;
           
        }
    }
    &.react-dropdown-select-item{
        background:none !important;
        color: $primaryFontColor;
    }
}

.account-types{
    .react-dropdown-select-item-selected{
        background: none !important;
        &:hover{
            background:#f6f6f6 !important;
        }
    }
    .react-dropdown-select-dropdown{        
        span{
            background: none !important;
            border-bottom:1px solid #ccc;
            padding-top:10px;
            padding-bottom:10px;
            &:hover{
                background:#f6f6f6 !important;
                font-weight: 600;
            }
        }
    }

    .react-dropdown-select-content{
        font-size:14px;
        text-align: bottom;
        span{        
            margin-top:50px;
            background: transparent !important;
            padding-top:0px !important;
            padding-bottom: 0px !important;
            font-weight: normal !important;
        }
    }
    &.react-dropdown-select-item{
        background:none !important;
    }
}

.flip{
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
}

.modal-dialog{
    max-width: 80vw !important;
}

.btn-primary{
    border-color:#000 !important;
    &:hover{
        border-color: #000 !important;
    }
}

a:hover{
    color: black !important;
}

.modal-backdrop{
    opacity: 0.8 !important;
}

.filterScroll{
    max-height: 350px;
    overflow:auto;
}

.mt-10{
    margin-top:20%;
}