@import "../../assets/css/Variables.scss";

.icon{
    border-radius: 3px;
    padding: 3px 8px;
    color: white;
    width: 30px;
    height: 30px;
    color: black;
    float: left;
    cursor: pointer;

    &::hover{
        color: white;
    }

}

.iconBig{
    width: 35px;
    height: 35px;
    color: black;
    float: left;
    cursor: pointer;
    background-color: $light-ash-color;

    &::hover{
        background: $pimary-dark-color;
        color: white;
        background-position: 8px 9px;
        background-repeat: no-repeat;
        background-color: $pimary-dark-color;
    }
        
}
