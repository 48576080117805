@import "../../assets/css/Variables.scss";

.downloadMenu {
    top: 30px;
    left: -5px;
    z-index: 9999;
    width: 257px;
    overflow: hidden;
    padding: 10px;

    ul {
        border: 1px solid $boarder-color;
        background: white;
        color: black;
        cursor: pointer;
        list-style: none;
        padding: 0px;
        margin: 0px;
        text-align: left;
        box-shadow: 0px 4px 4px $dark-ash-color;

        li {
            padding: 10px 10px 10px 19px;
        }

        li:hover {
            background-color: $pimary-dark-color;
        }

        li:hover a {
            color: white !important;
        }

        li a {
            color: black;
            text-decoration: none;
            font-size: 13px;
            border: none;
        }
    }
}

.inlineDownloadList{
     ul {
        
        list-style-type: none;
        margin: 0;
        padding: 0;
        overflow: hidden;

        li{
            display: inline;

            a{
                cursor: pointer;
                background: $light-ash-color;
                padding: 8px 20px 8px 40px;
                float: left;
                font-size: 14px;
                background: url('../../assets/images/icon-download.svg') no-repeat 16px 10px $light-ash-color;
                color: #000000;
                margin-right:10px;

                &:hover{
                    background-color: $pimary-dark-color;
                    color: #ffffff !important;
                    background: url('../../assets/images/icon-download_hover.svg') no-repeat 16px 10px $pimary-dark-color;
                }
            }
        }
     }
     
}

.dropdownContainer{
    width:80%;  
    
}



.dropdown{
    background:black;
    border-radius:5px;
    color:white;
    input::placeholder{
        color:white !important;
    }
    &:focus, &:hover{
        border-color: black !important;
        box-shadow: none !important;
    }
}