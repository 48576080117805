.grideModeFont{
    font-size: 15px;
}

.activeSort{
    font-weight: bold;
    background:#000 !important;
    color:#fff !important;
    border:1px solid #000;
    &:hover{
        color:white !important;
    }
}

.defaultSort{
    background:#fff !important;
    color:#000 !important;
    border:1px solid #000;
}