.resultContainer {
  margin-bottom: 12rem;
}
.resultTop {
  padding-top: 50px;
}

.hiddenElement {
  width: 100%;
  height: 25px;
  border: 0;
  background: transparent;
  cursor: context-menu !important;
}
