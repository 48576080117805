.centered {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index:100000;
}
.backdrop{
    background: rgba(0,0,0,0.3);
    z-index:999;
    position: fixed;
    width:100%;
    height:100vh;
    top:0;
    bottom:0;
}