@import "../../assets/css/Variables.scss";

.cattitle {
  font-size: 14px;
  font-weight: bold;
  // text-transform: uppercase;
}

.bodyText {
  font-size: 14px;
}

.check{
  label{
    font-size: 13px;
  }
  input[type="checkbox"] {
    width: 12px;
    border-radius: 2px;
    height: 15px;
    border: 1px solid #53565A;
  }
  input[type="checkbox"]:disabled ~ .selectedMark {
    opacity: 0.2;
}
}

.buttonStyle
{
    width: 177px;
    height :30px;
    font-style: normal;
    font-weight:400;
    line-height: 17px;
    letter-spacing: -0.02em;
    text-align: center;
    font-size: 14px;
    text-transform: uppercase;
    color: $primaryFontColor;
    background: $default-button-color;
    border: $light-ash-color;
    margin-right: 15px;
    &:hover {
        background-color: $default-button-color !important;
      }
    &:focus {
        background-color: $default-button-color !important;
      }
    &:active{
      background-color: $default-button-color !important;
    }
    &:disabled{
      background-color: $default-button-color !important;
      opacity: 0.5;
    }
}
.bodyAlign{
  padding-left: 15px;
}