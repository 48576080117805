@import "../../assets/css/Variables.scss";

.buttonStyle
{
    font-style: normal;
    font-weight:bold;
    height:38px;
    line-height: 17px;
    letter-spacing: -0.02em;
    text-align: center;
    font-size: 16px;
    text-transform: uppercase;
    color: $primaryFontColor;
    background: $default-button-color;
    border: $light-ash-color;
    &:hover {
      background-color: $default-button-color !important;
    }
  &:focus {
      background-color: $default-button-color !important;
    }
  &:active{
    background-color: $default-button-color !important;
  }
}

.buttonMargin{
  margin-right: 0rem !important;
}
@media screen and (max-width: 1400px) {
  .buttonStyle{
    font-size: 14px;
  }
}



