.filterHeading{
    font-family: SuissebyJLBook;
    font-size: 14px;
    text-transform: uppercase;
    font-weight:  bold;
    cursor: pointer;
}

.filterIcon{
    width: 8px;
}

.filterHeight{
    padding-bottom: 5px;
    margin-bottom: 0px;
}